
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import styles from './index.module.scss';
import Layout from 'components/Layout';
import { Namespace } from 'enums';
import { StaticPathParams } from 'typings/staticPaths';
import { BrowseSkeleton, FAQList } from 'components';
import AppLink from 'components/AppLink/AppLink';
import {
  debounce,
  getNoTrialTranslationKeyIfNeeded,
  localeFromHost,
  Permalink,
  shuffleArray,
  slugifyString,
} from 'helpers';
import { basicSEO } from 'helpers/jsx';
import { useWelcomeTheatresRow } from 'hooks';
import Analytics from 'helpers/Analytics';
import { authorizeUserServerSide } from 'api/firebaseAdmin';
import { GetPromotedContentResponsePlay, GetPromotedContentResponseTheatre } from 'typings/notGenerated';
import { PlayBase } from 'typings/api';
import prices from 'config/prices';
import DramoxImage from 'components/DramoxImage';
import { useAuth } from 'context';
import router from 'next/router';
import { HomeSkeleton } from 'components/HomeSkeleton';
import { homeCls, homeContentCls } from 'constants/ui';
import { Country } from 'enums/country';

interface Props {
  libraryPlays: PlayBase[];
  theatres: GetPromotedContentResponseTheatre[];
  plays: GetPromotedContentResponsePlay[];
  theatresTotalCount: number;
  priceMonthly: string;
  priceYearly: string;
  priceYearlyDiscount: string;
  locale: string;
  userSessionExists: boolean;
  countryCode: string;
}

const Welcome = ({
  theatres,
  theatresTotalCount,
  plays,
  libraryPlays,
  priceMonthly,
  priceYearly,
  priceYearlyDiscount,
  locale,
  userSessionExists,
  countryCode,
}: Props): JSX.Element => {
  const ref = React.useRef(null);
  const { t } = useTranslation(Namespace.Welcome);
  const { isUserLoaded, user } = useAuth();
  const [didScrollToContent, setDidScrollToContent] = useState(false);

  useWelcomeTheatresRow(ref, theatres, true);

  useEffect(() => {
    if (isUserLoaded && user) {
      router.replace(Permalink.collection(locale));
    }
  }, [isUserLoaded, user]);

  useEffect(() => {
    const handleScroll = () => {
      setDidScrollToContent(window.scrollY > 370);
    };

    const debounceScroll = debounce(handleScroll);

    document.documentElement.classList.add(homeCls);

    if (window) {
      window.addEventListener('scroll', debounceScroll, false);

      return () => {
        window.removeEventListener('scroll', debounceScroll, false);
        document.documentElement.classList.remove(homeCls, homeContentCls);
      };
    }
  }, []);

  const userRelatedCountryCode = useMemo(() => {
    return user?.home_country ?? countryCode;
  }, [user, countryCode]);

  useEffect(() => {
    document.documentElement.classList[didScrollToContent ? 'add' : 'remove'](homeContentCls);
  }, [didScrollToContent]);

  if (userSessionExists && !isUserLoaded) {
    return <HomeSkeleton countryCode={countryCode} />;
  } else if (isUserLoaded && !user) {
    // intentional empty if - do nothing
  } else if (user) {
    return <BrowseSkeleton countryCode={countryCode} />;
  }

  return (
    <div className={classNames(styles.wrapper, styles.visible)}>
      <Layout countryCode={countryCode}>
        <Head>{basicSEO(t('seo:welcome_title'), t('seo:welcome_description'))}</Head>
        <main className={classNames(styles.welcome)}>
          <div className={classNames(styles.action)}>
            <div className={classNames(styles.heading)}>
              <h1>{t(`welcome_title_${countryCode}`)}</h1>
              <p>{t(`welcome_subtitle_${countryCode}`)}</p>
            </div>
            <div className={styles.buttons}>
              <AppLink href={Permalink.registerStep1(locale)}>
                <a className={classNames('btn', 'btn-primary')} data-register="true" data-register-from="welcome/first">
                  {t(getNoTrialTranslationKeyIfNeeded(`welcome_button_long_${countryCode}`, userRelatedCountryCode))}
                </a>
              </AppLink>
              <AppLink href={Permalink.collection(locale)}>
                <a className={classNames('btn', 'btn-outline-info')}>{t(`welcome_button_browse_${countryCode}`)}</a>
              </AppLink>
            </div>
            <p className={classNames(styles.subscriptionInfo)}>
              {t(`welcome_price_${countryCode}`, { monthly: priceMonthly })}
            </p>
            <div className={styles.thumb} style={{ backgroundImage: `url(/jpg/img-cover-${countryCode}.jpg)` }}>
              <div className={styles.overlay} />
            </div>
          </div>

          <h2>{t('theatres_list_title')}</h2>

          <div className={classNames(styles.theaters, 'theaters', 'swipe-theater')} ref={ref}>
            <ul className={classNames('swiper-wrapper')}>
              {theatres.map((theatre) => {
                const slug = slugifyString(theatre.title);
                const { title } = theatre;
                return (
                  <li key={theatre.id} className={classNames('swiper-slide')}>
                    <DramoxImage src={theatre.poster_url} height={70} width={70} quality={90} />
                    <span className={classNames(styles.tooltiptext)}>{title}</span>
                    <AppLink href={Permalink.theatre(theatre, locale)}>
                      <a>{title}</a>
                    </AppLink>
                  </li>
                );
              })}
              {theatresTotalCount > 9 ? (
                <li className={classNames(styles.more, 'swiper-slide')}>
                  <AppLink href={Permalink.theatres(locale)}>
                    <a>+{theatresTotalCount - 9}</a>
                  </AppLink>
                </li>
              ) : null}
            </ul>
            <p>{t(`new_welcome_sec_1_title_${countryCode}`)}</p>
          </div>

          <h2>{t('plays_list_title')}</h2>

          <div className={classNames(styles.fromTopGradient)}>
            <div className={classNames(styles.imgTiles)}>
              <ul className={classNames(styles.imgCol)}>
                {plays.slice(0, 2).map((play, i) => (
                  <li key={play.id}>
                    <AppLink href={Permalink.play(play, locale)}>
                      <a className={classNames(styles.imgTile, i === 0 ? styles.imgTileLarge : null)}>
                        <em>
                          <DramoxImage
                            placeholderClass={classNames(styles.imgTile, i === 0 ? styles.imgTileLarge : null)}
                            src={play.poster_url}
                            height={570}
                            quality={80}
                          />
                        </em>
                        <span>
                          <img src={play.logo_url} alt={play.title} />
                          <h3>{play.theatre.title}</h3>
                        </span>
                      </a>
                    </AppLink>
                  </li>
                ))}
              </ul>
              <ul className={classNames(styles.imgCol)}>
                {plays.slice(2).map((play) => (
                  <li key={play.id}>
                    <AppLink href={Permalink.play(play, locale)}>
                      <a className={classNames(styles.imgTile)}>
                        <em>
                          <DramoxImage
                            placeholderClass={classNames(styles.imgTile)}
                            src={play.poster_url}
                            height={570}
                            quality={80}
                          />
                        </em>
                        <span>
                          <img src={play.logo_url} alt={play.title} />
                          <h3>{play.theatre.title}</h3>
                        </span>
                      </a>
                    </AppLink>
                  </li>
                ))}
                <li>
                  <AppLink href={Permalink.theatres(locale)}>
                    <a className={classNames(styles.imgTile, styles.library)}>
                      <ul>
                        {libraryPlays?.map((play, i) => (
                          <li key={i} className={classNames(styles[`lib${i}`])}>
                            <DramoxImage
                              placeholderClass={classNames(styles[`lib${i}`])}
                              src={play.poster_url}
                              height={128}
                              quality={80}
                            />
                          </li>
                        ))}
                      </ul>
                      <div>
                        <strong>{t(`new_welcome_sec_2_title_${countryCode}`)}</strong>
                        <p>{t(`new_welcome_sec_2_text_${countryCode}`)}</p>
                      </div>
                    </a>
                  </AppLink>
                </li>
              </ul>
            </div>
          </div>
          <div className={classNames(styles.secMargin)}>
            <div className={classNames(styles.section)}>
              <div className={classNames(styles.pic, styles.pic1)}>
                <img
                  srcSet={`/jpg/img-tv-${countryCode}@1x.jpg 1x, /jpg/img-tv-${countryCode}@2x.jpg 2x`}
                  src={`/jpg/img-tv-${countryCode}@1x.jpg`}
                />
                <div>
                  <h2>{t(`new_welcome_sec_3_title_${countryCode}`)}</h2>
                  <p>{t(`new_welcome_sec_3_text_${countryCode}`)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.section)}>
            <div className={classNames(styles.pic, styles.pic2)}>
              <img
                srcSet={`/jpg/img-mobile-${countryCode}@1x.jpg 1x, /jpg/img-mobile-${countryCode}@2x.jpg 2x`}
                src={`/jpg/img-mobile-${countryCode}@1x.jpg`}
              />
              <div>
                <h2>{t(`new_welcome_sec_4_title_${countryCode}`)}</h2>
                <p>{t(`new_welcome_sec_4_text_${countryCode}`)}</p>
              </div>
            </div>
          </div>
          <div className={classNames(styles.fromTopGradient)}>
            <div className={classNames(styles.faq)}>
              <h2>{t('menu_faq')}</h2>
              <div id="faq">
                <FAQList
                  type="short"
                  priceMonthly={priceMonthly}
                  priceYearly={priceYearly}
                  priceYearlyDiscount={priceYearlyDiscount}
                  countryCode={user?.home_country ?? countryCode}
                />
              </div>
            </div>
          </div>
          <div className={classNames(styles.try)}>
            <div className={classNames(styles.faq, styles.tryDramox)}>
              <h2>{t(`new_welcome_sec_5_title_${countryCode}`)}</h2>
              <p>{t(`new_welcome_sec_5_text_${countryCode}`)}</p>
              <AppLink href={Permalink.registerStep1(locale)}>
                <a
                  className={classNames('btn', 'btn-primary')}
                  data-register="true"
                  data-register-from="welcome/fourth"
                >
                  {t(getNoTrialTranslationKeyIfNeeded(`welcome_button_long_${countryCode}`, userRelatedCountryCode))}
                </a>
              </AppLink>
            </div>
          </div>
        </main>
      </Layout>
    </div>
  );
};

 async function _getServerSideProps(
  ctx: GetServerSidePropsContext<StaticPathParams>,
): Promise<GetServerSidePropsResult<Props>> {
  const { locale, apiClient, userSessionExists, countryCode } = await authorizeUserServerSide(ctx);

  try {
    const { theatres, plays, total_theatres_count } = await apiClient.getHomepageContent();
    const libraryPlays = shuffleArray(await apiClient.getPlays()).slice(0, 3);
    const plans = await apiClient.getPlans();

    let priceMonthly: string;
    let priceYearly: string;
    let priceYearlyDiscount: string;
    const planMonthly = plans.find((p) => p.recurrence.unit === 'MONTH' && p.recurrence.length === 1);
    const planYearly = plans.find((p) => p.recurrence.unit === 'YEAR' && p.recurrence.length === 1);

    if (planMonthly && planYearly) {
      priceMonthly = planMonthly.price.formatted;
      priceYearly = planYearly.price.formatted;
      const currency = planYearly.price.currency;
      priceYearlyDiscount = priceMonthly
        .replace(',', '.')
        .replace('0', '')
        .replace(planMonthly.price.value.toString(), prices[currency].yearPriceDiff.toString());
    }

    return {
      props: {
        theatres,
        plays,
        theatresTotalCount: total_theatres_count,
        libraryPlays,
        priceMonthly,
        priceYearly,
        priceYearlyDiscount,
        locale,
        userSessionExists,
        countryCode,
      },
    };
  } catch (e) {
    console.log(e);
  }
}

export default Welcome;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  