import classNames from 'classnames';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Layout from '../Layout';
import styles from './index.module.scss';

interface Props {
  countryCode: string;
}

export const HomeSkeleton: React.FC<Props> = ({ countryCode }) => {
  return (
    <Layout countryCode={countryCode} allowPopups={false}>
      <main className={styles.welcome}>
        <SkeletonTheme baseColor="#22222E" highlightColor="#2D2D3A">
          <div className={classNames(styles.action)}>
            <div className={classNames(styles.heading)}>
              <h1>
                <Skeleton className={styles.h1} />
              </h1>
              <p>
                <Skeleton className={styles.p} />
              </p>
            </div>
          </div>

          <div className={classNames(styles.theaters, 'theaters', 'swipe-theater')}>
            <ul className={classNames('swiper-wrapper')}>
              <Skeleton width={673} height={70} />
            </ul>
            <p>
              <Skeleton width={300} height={70} />
            </p>
          </div>

          <div className={classNames(styles.fromTopGradient)}>
            <div className={classNames(styles.imgTiles)}>
              <ul className={classNames(styles.imgCol)}>
                <li>
                  <em className={classNames(styles.imgTile)}>
                    <Skeleton height={270} />
                  </em>
                </li>
                <li>
                  <em className={classNames(styles.imgTile)}>
                    <Skeleton height={270} />
                  </em>
                </li>
              </ul>
              <ul className={classNames(styles.imgCol)}>
                <li>
                  <em className={classNames(styles.imgTile)}>
                    <Skeleton height={270} />
                  </em>
                </li>
                <li>
                  <em className={classNames(styles.imgTile)}>
                    <Skeleton height={270} />
                  </em>
                </li>
              </ul>
            </div>
          </div>
        </SkeletonTheme>
      </main>
    </Layout>
  );
};
